import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Button, Modal } from '../design'
import { setIsSubscribedToUpdates } from '../utils/storage'
import { EMAIL_REG_EXP, WRONG_EMAIL_FORMAT } from '../utils/constants'
import './SubscriptionModal.scss'

const SubscribeForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [result, setResult] = useState()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const submit = async data => {
    setIsLoading(true)
    const result = await addToMailchimp(data.email)
    setIsSubscribedToUpdates()
    setResult(result)
    setIsLoading(false)
    setEmail(data.email)
  }

  if (isLoading) return <p className="modal-subscription__status-message">Одну секунду...</p>
  if (result?.result === 'success')
    return (
      <p className="modal-subscription__status-message">
        Спасибо что подписались на нашу рассылку. Вперед к знаниям. Вместе! Поехали!
      </p>
    )
  if (result?.result === 'error') {
    if (result.msg.includes('is already subscribed to list')) {
      return <p className="modal-subscription__status-message">{`Адрес ${email} уже добавлен в список рассылки`}</p>
    }
    return <p className="modal-subscription__error-message">Ошибка оформления подписки</p>
  }

  return (
    <>
      <h2>Подписаться на уведомления</h2>
      <p>
        Будьте в числе первых, кто узнает про запуск наших интерактивных курсов и добавление новых категорий бесплатных
        вопросов
      </p>
      <form onSubmit={handleSubmit(submit)}>
        <input
          className="modal-subscription__input"
          placeholder="Ваш Email"
          {...register('email', {
            required: true,
            pattern: {
              value: EMAIL_REG_EXP,
              message: WRONG_EMAIL_FORMAT,
            },
          })}
        />
        {errors.email && (
          <p className="modal-subscription__status-message">{errors.email.message || 'Пожалуйста, введите email'}</p>
        )}
        <br />
        <Button className="modal-subscription__button" size="large" htmlType="submit">
          Подписаться
        </Button>
      </form>
      <p className="modal-subscription__policy-note">
        Нажимая на кнопку, Вы принимаете условия{' '}
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
          политики конфиденциальности
        </a>
      </p>
    </>
  )
}

const SubscriptionModal = ({ isOpen, toggleModal }) => {
  return (
    <Modal isOpen={isOpen} handleClose={() => toggleModal(false)}>
      <div className="modal-subscription__content">
        <SubscribeForm />
      </div>
    </Modal>
  )
}

export default SubscriptionModal
