import React from 'react'
import { navigate } from 'gatsby'
import { sendAnalyticsEvent, AnalyticsData } from '../utils/ga'
import './CategoryCard.scss'

const CategoryCard = ({ title, sampleQuestion, category }) => {
  const onClick = () => {
    sendAnalyticsEvent('click', new AnalyticsData(`category-card-${category}`))
    navigate(`/simulator#category=${category}`)
  }

  return (
    <div className="category-card" onClick={onClick}>
      <div className="category-card__question">
        <p className="body-large">{sampleQuestion}</p>
      </div>
      <div className="category-card__title">
        <p className="body-medium">{title}</p>
      </div>
    </div>
  )
}

export default CategoryCard
