const IS_SUBSCRIBED_TO_UPDATES = 'isSubscribedToUpdates'
const HAS_MEDIA_PERMISSIONS = 'hasMediaPermissions'

const getIsSubscribedToUpdates = () => {
  return !!localStorage.getItem(IS_SUBSCRIBED_TO_UPDATES)
}

const setIsSubscribedToUpdates = () => {
  localStorage.setItem(IS_SUBSCRIBED_TO_UPDATES, true)
}

const removeIsSubscribedToUpdates = () => {
  localStorage.removeItem(IS_SUBSCRIBED_TO_UPDATES)
}

const getHasMediaPermissions = () => {
  return !!localStorage.getItem(HAS_MEDIA_PERMISSIONS)
}

const setHasMediaPermissions = () => {
  localStorage.setItem(HAS_MEDIA_PERMISSIONS, true)
}

const removeHasMediaPermissions = () => {
  localStorage.removeItem(HAS_MEDIA_PERMISSIONS)
}

export {
  getIsSubscribedToUpdates,
  setIsSubscribedToUpdates,
  removeIsSubscribedToUpdates,
  getHasMediaPermissions,
  setHasMediaPermissions,
  removeHasMediaPermissions,
}
