import React from 'react'
import classNames from 'classnames'
import './Button.scss'

const Button = ({ children, htmlType = 'button', className, type = 'primary', size, onClick, loading, disabled }) => {
  const buttonClass = classNames(`btn btn--${type} ${className ? className : ''}`, {
    'btn--size-lg': size === 'large',
  })
  const textClass = classNames('btn__text', { 'btn__text--hide': loading })
  return (
    <button className={buttonClass} type={htmlType} onClick={onClick} disabled={disabled}>
      {loading && <span className="btn__spinner"></span>}
      <span className={textClass}>{children}</span>
    </button>
  )
}

export default Button
