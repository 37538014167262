import React from 'react'
import classNames from 'classnames'
import Navbar from './Navbar'
import Footer from './Footer'
import SEO from './SEO'
import FooterMinimal from './FooterMinimal'
import '../styles/styles.scss'
import './Layout.scss'

const Layout = ({ children, className, landing, centerContentVertically, renderBottomContent, hideFooter }) => {
  const contentClass = classNames(`layout-body-container-content ${className ? className : ''}`, {
    'layout-body-container-content--center-vertically': centerContentVertically,
  })

  const getFooter = () => {
    return landing ? <Footer /> : <FooterMinimal />
  }

  return (
    <div className="layout-body-container">
      <SEO />
      <Navbar landing={landing} />
      <div className={contentClass}>{children}</div>
      {renderBottomContent && renderBottomContent()}
      {!hideFooter && getFooter()}
    </div>
  )
}

export default Layout
