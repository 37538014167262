export const color = {
  white: '#fff',
  black: '#1A1A1A',
  green: '#67BC75',
  red30: '#ef7070',
  gray10: '#f2f2f2',
  gray50: '#a3a3a3',
  gray95: '#383838',
  purple30: '#7573C1',
  purple95: '#1c1b57',
}

export const transition = '0.2s'

export const maxWidthMedium = '1080px'
export const maxWidthNarrow = '600px'

export const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 700,
}

export const breakpoint = {
  xsmall: '480px',
  small: '576px',
  medium: '768px',
  large: '992px',
  xlarge: '1200px',
  xxlarge: '1400px',
}
