import React, { useState } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { useApp } from '../context/appContext'
import Logo from './Logo'
import './Navbar.scss'

const Navbar = ({ landing }) => {
  const { isAuthenticated, signOut } = useApp()
  const [isOpen, setIsOpen] = useState(false)

  const sectionOuterClass = classNames('section-navbar', { 'section-outer--gray': landing })
  const menuClass = classNames('navbar-menu', { 'navbar-menu--gray': landing, 'navbar-menu--open': isOpen })

  const onLogout = async () => {
    await signOut()
    navigate('/')
  }

  const getAuthMenu = () => {
    return isAuthenticated() ? (
      <>
        <Link to="/user/dashboard" className="navbar-menu-menu-item" activeClassName="navbar-menu-menu-item--active">
          Мои курсы
        </Link>
        <Link to="/user/profile" className="navbar-menu-menu-item" activeClassName="navbar-menu-menu-item--active">
          Профиль
        </Link>
        <span className="navbar-menu-menu-item" onClick={onLogout}>
          Выход
        </span>
      </>
    ) : (
      <>
        <Link to="/login" className="navbar-menu-menu-item" activeClassName="navbar-menu-menu-item--active">
          Вход
        </Link>
        <Link to="/register" className="navbar-menu-menu-item" activeClassName="navbar-menu-menu-item--active">
          Регистрация
        </Link>
      </>
    )
  }

  return (
    <header className={sectionOuterClass}>
      <div className="section-inner section-inner vertical-indent--s">
        <Logo />
        <div className="navbar-hamburger" onClick={() => setIsOpen(!isOpen)}>
          <span />
          <span />
          <span />
        </div>
        <nav className={menuClass}>
          <Link to="/categories" className="navbar-menu-menu-item" activeClassName="navbar-menu-menu-item--active">
            Тренажер интервью
          </Link>
          <Link to="/about" className="navbar-menu-menu-item" activeClassName="navbar-menu-menu-item--active">
            О проекте
          </Link>
          {/* {getAuthMenu()} */}
        </nav>
      </div>
    </header>
  )
}

export default Navbar
