function sendAnalyticsEvent(action, data) {
  if (!window.gtag) return

  window.gtag('event', action, { ...data })
}

class AnalyticsData {
  constructor(label, category = 'engagement', value) {
    this.event_category = category
    this.event_label = label
    this.value = value
  }
}

export { sendAnalyticsEvent, AnalyticsData }
